<template>
  <div>
    <div class="row">
      <div class="other-expenses-header">
        <div>
          <div class="other-expenses-title">Despesas realizadas</div>
        </div>
        <div class="other-expenses-actions">
          <div 
            class="manage-layouts" 
            @click="manageLayouts"
            v-b-tooltip.hover :title="layouts.length ? '' : 'Defina um layout primeiro'"
          >
            <ExpenseIcon class="icon-box" :class="{'active':layouts.length, 'readonly': !layouts?.length }"/>
            <span :class="{'active':layouts.length, 'readonly': !layouts?.length}">
              Layouts
            </span>
          </div>
          <Search 
            class="icon search" 
            v-if="!readonly"
            v-b-modal.layout-select-modal
            v-b-tooltip.hover title="Buscar layout de despesas" 
          />
        </div>
      </div>

      <ExpenseModuleTable 
        :items="form.items"
        :copyExpense="copyExpense"
        :editExpense="editExpense"
        :deleteExpense="deleteExpense"
        :readonly="readonly"
      />

      <div class="col-12 text-center">
        <b-button variant="link" :disabled="readonly" @click="addRealizedExpenses">
          Adicionar despesas realizadas
        </b-button>
      </div>

      <div class="col-12">
        <hr id="hr" />
      </div>
    </div>

    <ExpensesFormModal
      :form="form"
      :item="expenseEdit"
      :index="index"
      :cds="cds"
      :datetime="datetime"
      :unitMeasurementOptions="unitMeasurementOptions"
      @onHide="clearExpenseEdit"
      :clinicHealthPlan="clinicHealthPlan"
      :readonly="readonly"
    />
    <LayoutSelect 
      :clinicHealthPlan="clinicHealthPlan"
      :selectedLayouts="layouts"
      @addLayouts="addLayoutExpenses"
    />
    <ManageLayoutsModal 
      :layouts="layouts"
      @delete="deleteLayoutExpense"
      :withDelete="!readonly"
    />
  </div>
</template>

<script>
import { parseNumberToMoney } from '@/utils/moneyHelper'
import unitMeasures from '@/components/Tiss/Data/unitMeasures'
import cdsOptions from '@/components/Tiss/Data/cds'

export default {
  name: 'ExpensesModule',
  components: {
    ExpensesFormModal: () => import("../ExpensesFormModal"),
    Search: () => import("@/assets/icons/search.svg"),
    ExpenseIcon: () => import("@/assets/icons/product.svg"),
    LayoutSelect: () => import("@/layouts/InvoicingTiss/Layouts/LayoutSelect.vue"),
    ManageLayoutsModal: () => import("@/components/Tiss/Modal/ManageLayoutsModal.vue"),
    ExpenseModuleTable: () => import('@/components/Tiss/components/ExpenseModuleTable'),
  },
  props: {
    form: Object,
    readonly: Boolean,
    clinicHealthPlan: Object
  },
  data() {
    return {
      materialOptions: [],
      expenseEdit: {},
      index: 0,
      cds: cdsOptions,
      unitMeasurementOptions: unitMeasures,
      layouts: [],
      datetime: null,
    }
  },
  async mounted() {
    this.setLayoutsByItems()
  },
  methods: {
    parseNumberToMoney,
    async getTussMaterial() {
      try {
        const { data } = await this.api.getTussMaterial('')
        this.materialOptions = data.map(material => {
          return material
        })
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    copyExpense (item) {
      this.form.items.push({ ...item, id: null, layout_id: null })
    },
    editExpense(item, index) {
      this.expenseEdit = item
      this.index = index
      this.$bvModal.show('add_realized_expenses_modal')
    },
    async deleteExpense(item, index) {
      if (item.id) {
        await this.deleteTissGuideItem(item.id)
      }
      this.form.items.splice(index, 1)
    },
    addRealizedExpenses() {
      this.$bvModal.show('add_realized_expenses_modal')
    },
    clearExpenseEdit() {
      this.expenseEdit = {}
      this.index = 0
    },
    async deleteTissGuideItem(id) {
      const isLoading = this.$loading.show()
      try {
        await this.api.deleteTissGuideItem(id)
        this.$toast.success('Despesa removida com sucesso!')
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    addLayoutExpenses(layouts) {
      this.layouts = layouts
      this.form.items = this.form.items.filter(
        item => !(Object.keys(item).includes('layout_id'))
      )

      this.layouts.map(layout => {
        layout.expenses.map(expense => {
          this.$set(expense, 'layout_id', layout.id)
          this.$set(expense, 'id', null)
          this.$set(expense, 'decrease_or_increase_factor', expense.decrease_or_increase_factor || 1)
          if (this.datetime && Object.keys(this.datetime).length) {
            this.$set(expense, 'execution_date', this.datetime.execution_date ? this.moment(this.datetime.execution_date).toDate() : null)
            this.$set(expense, 'start_time', this.datetime.start_time)
            this.$set(expense, 'end_time', this.datetime.end_time)
          }
          this.form.items.push(expense)
        })
      })
    },
    manageLayouts() {
      if (!this.layouts.length) return
      this.$bvModal.show('manage-layouts-modal')
    },
    deleteLayoutExpense(index) {
      const layout = this.layouts[index]
      const items = []
      this.form.items.map((item, index) => {
        if (item?.layout_id === layout.id) 
          item?.id && this.deleteTissGuideItem(item.id)
        else 
          items.push(item)
      })
      this.$set(this.form, 'items', items)
      this.$set(this, 'layouts', this.layouts.filter((layout, i) => i !== index))
    },
    setLayoutsByItems() {
      this.form.items.map(item => {
        if (item?.layout_id 
            && !(this.layouts.find(layout => layout.layout_id === item?.layout_id))
        ) {
          this.layouts.push({
            ...item.layout,
            layout_id: item.layout_id,
            expenses: this.form.items.filter(
              expense => expense.layout_id === item.layout_id
            ),
          })
        }
      })
    },
    async getSpSadtDateTimes() {
      try {
        const { data } = await this.api.getSpSadtDateTimes(this.form.expensable_id)
        this.datetime = data
      } catch(error) {
        this.$toast.error(error.message)
      }
    }
  },
  watch: {
    form: {
      handler: async function() {
        this.form.expensable_id && await this.getSpSadtDateTimes()
      },
      deep: true,
    }
  }
}
</script>

<style lang="scss" scoped>

@import '@/assets/scss/table.scss';
.icon {
  width: 24px;
  height: 24px;
  transform: scale(0.8);
  cursor: pointer;

  &.copy {
    fill: var(--neutral-700);
  }
  &.edit {
    stroke: var(--blue-500);
  }

  &.delete {
    stroke: var(--red-500);
  }
}
.icon-box {
  width: 24px;
  height: 24px;
  margin-top: 5px;
}
.other-expenses-header {
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1rem;
  div {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
}

.other-expenses-title {
  font-weight: 600;
  font-size: 16px;
  color: var(--neutral-600);
}

.item-title {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: #1f3278;
  margin: 0;
  margin-top: 10px
}

.second-table {
  background-color: var(--neutral-100);
}

.table-label {
  font-family: 'Nunito Sans';
  font-weight: 700;
  font-size: 14px;
}

.search {
  transform: scale(0.85);
  stroke: var(--neutral-500);
}

.manage-layouts {
  cursor: pointer;
  margin-left: 80px;
  width: 50%;

  span {
    font-weight: 600;
  }

  svg {
    width: 20px;
    height: 20px;
  }

  .active {
    color: var(--blue-500);
    stroke: var(--blue-500);
  }


  .readonly {
    color: var(--neutral-400);
    stroke: var(--neutral-200);
  }
}

.other-expenses-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}
</style>
